import { BASE_URL } from "../../services/apiConfig";
import useCopyStore from "../../store/useCopyStore";
import { useEffect, useState, useRef } from "react";
import searchButtonIcon from "../../assets/images/search_button.png";

const SearchStoreSection = () => {
	const { copies, getCopy } = useCopyStore();
	const [isVisible, setIsVisible] = useState(false);
	const searchSectionRef = useRef(null);
	const observerRef = useRef(null);

	useEffect(() => {
		const loadCopy = async () => {
			try {
				await getCopy("PAGE-004");
			} catch (err) {
				console.error("에러:", err);
			}
		};

		loadCopy();
	}, []);

	useEffect(() => {
		if (observerRef.current) {
			observerRef.current.disconnect();
		}

		observerRef.current = new IntersectionObserver(
			(entries) => {
				const [entry] = entries;

				if (entry.isIntersecting && entry.intersectionRatio >= 0.8) {
					setIsVisible(true);
				} else {
					setIsVisible(false);
				}
			},
			{
				root: null,
				rootMargin: "-10% 0px",
				threshold: [0, 0.2, 0.4, 0.6, 0.8, 1.0],
			}
		);

		const currentRef = searchSectionRef.current;

		if (currentRef) {
			requestAnimationFrame(() => {
				observerRef.current.observe(currentRef);
			});
		}

		return () => {
			if (observerRef.current) {
				observerRef.current.disconnect();
			}
		};
	}, []);

	return (
		<section
			ref={searchSectionRef}
			className="relative h-[522px] bg-cover bg-center opacity-0 transition-all duration-500"
			style={{
				backgroundImage: `url(${BASE_URL}/media/page_media_and_copy/search_store_bg.png)`,
				opacity: 1,
			}}
		>
			<div className="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-center items-center text-center">
				<div className="flex items-center justify-center flex-col w-full">
					<h2 className="text-white font-mont font-black text-[60px] mb-1">
						Search Store
					</h2>
					<h1 className="text-white font-pretendard font-medium text-[20px] mb-[74px]">
						{copies["PAGE-004"]?.content?.replace(/<[^>]*>/g, "") || "로딩중.."}
					</h1>
					{/* 검색창 */}
					<div
						className={`flex items-center gap-8 rounded-full p-[7.26px] bg-white transition-all duration-1000 ease-out ${
							isVisible ? "w-[80%]" : "w-[82px]"
						} max-w-[801px]`}
					>
						{isVisible && (
							<input
								type="text"
								placeholder="매장 찾기 바로가기"
								className={`bg-transparent border-none outline-none font-pretendard font-semibold text-[20px] transition-all duration-700 ${
									isVisible
										? "w-full ml-[30px] opacity-100"
										: "w-0 ml-0 opacity-0 p-0"
								}`}
							/>
						)}
						<img
							src={searchButtonIcon}
							alt="검색 버튼"
							className="w-[68px] h-[68px]"
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SearchStoreSection;
