import mainLogo from "../../assets/images/main_text_icon_logo_white.png";
import SocialLinks from "../common/SocialLinks";

const Footer = () => {
	return (
		<footer className="bg-primary text-white mt-8">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
				<div className="flex flex-col items-center">
					<div className="text-center mb-8 flex flex-col items-center">
						<img
							src={mainLogo}
							alt="봉천동 매운 떡볶이"
							className="h-10 mb-2"
						/>
						{/* 소셜 링크 세트 */}
						<SocialLinks className="mt-4" />
					</div>
					<div className="flex flex-col items-center mb-8 text-sm">
						<div className="flex flex-col items-center">
							<div className="flex flex-row items-center justify-center gap-4">
								<p>(주)스파이시즘 대표이사 강병양</p>
								<p>사업자 등록번호 : 792-81-01859</p>
								<p>주소 : 서울특별시 중구 동호로14길 19, 4층 426호</p>
							</div>
							<div className="flex flex-row items-center justify-center gap-4">
								<p>대표전화 : 02-0000-0000</p>
								<p>이메일: event@bongmaetteock@.co.kr</p>
							</div>
							<p className="text-white/80 mt-4">
								Copyright © 2024 봉천동 매운떡볶이. All rights reserved.
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
