import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import Franchise from "./pages/Franchise";
import Locations from "./pages/Locations";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Brand from "./pages/Brand";
import News from "./pages/News";
import NewsDetail from "./pages/NewsDetail";
import CustomCursor from "./components/common/CustomCursor";

function App() {
	return (
		<>
			<CustomCursor />
			<Router>
				<div className="flex flex-col min-h-screen">
					<Navbar />
					<main className="flex-grow">
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/menu" element={<Menu />} />
							<Route path="/franchise" element={<Franchise />} />
							<Route path="/locations" element={<Locations />} />
							<Route path="/privacy" element={<PrivacyPolicy />} />
							<Route path="/terms" element={<TermsOfService />} />
							<Route path="/brand" element={<Brand />} />
							<Route path="/news" element={<News />} />
							<Route path="/news/:id" element={<NewsDetail />} />
						</Routes>
					</main>
					<Footer />
				</div>
			</Router>
		</>
	);
}

export default App;
