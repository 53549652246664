import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchPopups } from "../../services/popupService";

const Popup = () => {
	const navigate = useNavigate();
	const [popups, setPopups] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const loadPopups = async () => {
			try {
				const data = await fetchPopups();
				// 오늘 하루 보지 않기로 한 팝업 필터링
				const filteredPopups = data.filter((popup) => {
					const hideUntil = sessionStorage.getItem(`popup_${popup.id}`);
					if (!hideUntil) return true;
					return new Date(hideUntil) < new Date();
				});
				setPopups(filteredPopups);
			} catch (err) {
				setError("팝업을 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadPopups();
	}, []);

	const handleHideToday = (popupId) => {
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		tomorrow.setHours(0, 0, 0, 0);
		sessionStorage.setItem(`popup_${popupId}`, tomorrow.toISOString());
		setPopups((prev) => prev.filter((popup) => popup.id !== popupId));
	};

	const handleClose = (popupId) => {
		setPopups((prev) => prev.filter((popup) => popup.id !== popupId));
	};

	const handleDetailClick = (popup) => {
		if (popup.link) {
			window.location.href = popup.link;
		} else if (popup.post) {
			navigate(`/news/${popup.post}`);
		}
	};

	if (loading || error || popups.length === 0) return null;

	return (
		<div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
			<div className="relative flex flex-wrap justify-center gap-4 max-w-7xl mx-4">
				{popups.map((popup) => (
					<div
						key={popup.id}
						className="relative bg-white rounded-lg shadow-xl max-w-md overflow-hidden"
					>
						{/* 닫기 버튼 */}
						<button
							onClick={() => handleClose(popup.id)}
							className="absolute top-4 right-4 text-white hover:text-gray-200 z-10"
							aria-label="팝업 닫기"
						>
							<svg
								className="w-6 h-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>

						{/* 팝업 내용 */}
						<div>
							<img
								src={popup.image}
								alt={popup.title}
								className="w-full h-auto"
							/>
							<div className="absolute bottom-0 left-0 right-0 bg-white/90 backdrop-blur-sm p-4 flex justify-between items-center">
								<button
									onClick={() => handleHideToday(popup.id)}
									className="text-sm text-gray-600 hover:text-gray-800"
								>
									오늘 하루 다시 보지 않기
								</button>
								{(popup.link || popup.post) && (
									<button
										onClick={() => handleDetailClick(popup)}
										className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded text-sm transition-colors inline-flex items-center group"
									>
										자세히 보기
										<svg
											className="w-4 h-4 ml-1 transform group-hover:translate-x-1 transition-transform"
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M9 5l7 7-7 7"
											/>
										</svg>
									</button>
								)}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Popup;
