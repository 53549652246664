import { useState, useEffect } from "react";
import { fetchMenuCategories } from "../services/menuService";

const Menu = () => {
	const [categories, setCategories] = useState([]);
	const [activeCategory, setActiveCategory] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const loadMenus = async () => {
			try {
				const data = await fetchMenuCategories();
				setCategories(data);
				setActiveCategory(data[0]?.name || null);
			} catch (err) {
				setError("메뉴를 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadMenus();
	}, []);

	if (loading)
		return (
			<div className="flex justify-center items-center min-h-screen">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);

	if (error)
		return (
			<div className="flex justify-center items-center min-h-screen text-red-600">
				{error}
			</div>
		);

	const activeMenus =
		categories.find((category) => category.name === activeCategory)?.menus ||
		[];

	return (
		<div className="py-16 bg-gray-50">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<h1 className="text-4xl font-bold text-center mb-12">메뉴</h1>

				{/* 카테고리 탭 */}
				<div className="flex justify-center mb-12">
					<div className="inline-flex rounded-lg border border-gray-200 bg-white p-1">
						{categories.map((category) => (
							<button
								key={category.name}
								onClick={() => setActiveCategory(category.name)}
								className={`px-4 py-2 rounded-md ${
									activeCategory === category.name
										? "bg-red-600 text-white"
										: "text-gray-600 hover:text-red-600"
								}`}
								aria-label={`${category.name} 메뉴 보기`}
							>
								{category.name}
							</button>
						))}
					</div>
				</div>

				{/* 메뉴 아이템 그리드 */}
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
					{activeMenus.map((item) => (
						<div
							key={item.id}
							className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
						>
							<div className="relative h-48 overflow-hidden">
								<img
									src={item.image}
									alt={item.name}
									className="w-full h-full object-cover"
								/>
								{item.is_signature && (
									<div className="absolute top-2 right-2 bg-red-600 text-white px-3 py-1 rounded-full text-sm">
										시그니처
									</div>
								)}
							</div>
							<div className="p-6">
								<div className="flex justify-between items-start mb-2">
									<h3 className="text-xl font-bold">{item.name}</h3>
									<span className="text-red-600 font-bold">
										{parseInt(item.price).toLocaleString()}원
									</span>
								</div>
								<p className="text-gray-600 mb-4">{item.description}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Menu;
