import React from "react";
import { useNavigate } from "react-router-dom";

const NewsCard = ({ post }) => {
	const navigate = useNavigate();

	return (
		<div
			className="cursor-pointer mx-auto transition-all duration-300 ease-out hover:opacity-90"
			onClick={() => navigate(`/news/${post.id}`)}
		>
			<div className="flex flex-col w-[291.4px]">
				{/* 이미지 컨테이너 */}
				<div className="aspect-w-1 aspect-h-1 relative overflow-hidden">
					<img
						src={post.banner_image || post.image}
						alt={post.title}
						className="w-full h-full object-cover transition-transform duration-300 ease-out hover:scale-110"
					/>
				</div>

				{/* 상태 뱃지 */}
				<div className="flex flex-col py-[12.58px] px-[10.9px] h-[86.1px] hover:bg-primary group transition-colors duration-300">
					<div className="flex items-center">
						{post.launch_date && post.end_date && (
							<span
								className={`text-[15.6px] font-pretendard font-semibold py-1 rounded-full group-hover:text-white transition-colors duration-300 ${
									new Date() < new Date(post.launch_date)
										? "text-blue-600"
										: new Date() > new Date(post.end_date)
										? "text-gray-600"
										: "text-primary"
								}`}
							>
								{new Date() < new Date(post.launch_date)
									? "준비중"
									: new Date() > new Date(post.end_date)
									? "종료"
									: "진행중"}
							</span>
						)}
					</div>

					{/* 제목 */}
					<h3 className="font-pretendard font-semibold text-[20.91px] line-clamp-1 group-hover:text-white transition-colors duration-300">
						{post.title.trim()}
					</h3>
				</div>
			</div>
		</div>
	);
};

export default NewsCard;
