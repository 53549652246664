import { useState, useEffect } from "react";
import { fetchCopy } from "../../services/copyService";
import { BASE_URL } from "../../services/apiConfig";
import { Link } from "react-router-dom";

const About = () => {
	const [copy, setCopy] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const loadCopy = async () => {
			try {
				const data = await fetchCopy("PAGE-001");
				setCopy(data);
			} catch (err) {
				setError("컨텐츠를 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadCopy();
	}, []);

	if (loading)
		return (
			<div className="flex justify-center items-center py-16">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);

	if (error)
		return (
			<div className="flex justify-center items-center py-16 text-red-600">
				{error}
			</div>
		);

	return (
		<section className="pt-[117.48px]">
			<div className="mx-auto">
				<div className="grid grid-cols-1 md:grid-cols-2">
					<Link
						to="/stores"
						className="group relative overflow-hidden shadow-lg"
					>
						<div
							className="aspect-[961/613] bg-cover bg-center transition-transform duration-300 group-hover:scale-110"
							style={{
								backgroundImage: `url(${BASE_URL}/media/guide_images/about1.png)`,
							}}
						/>
						<div className="absolute bottom-12 right-12  flex flex-col justify-end">
							<h3 className="text-white font-pretendard text-[30px] font-bold mb-2">
								브랜드 스토리
							</h3>
							<h1 className="font-mont text-white text-[50px] font-black mb-2">
								BRAND STORY
							</h1>
							<p className="text-white text-[20px]">
								최고의 순간을 봉매떡과 함께 즐겨보세요.
							</p>
							<div className="flex justify-start">
								<button
									className="text-[12px] mt-4 px-12 py-2 rounded-full font-bold
									relative bg-primary text-white overflow-hidden
									transition-all duration-300 ease-in-out
									hover:bg-lightgray hover:text-primary
									before:content-['']
									before:absolute before:bottom-[-100%] before:left-[-100%]
									before:w-[300%] before:h-[300%]
									before:bg-primary
									before:rotate-45
									before:transition-transform before:duration-500 before:ease-out
									hover:before:translate-x-[35%] hover:before:translate-y-[-35%]
									[&>p]:relative [&>p]:z-10"
								>
									<p className="font-urbane text-[18.43px]">자세히보기</p>
								</button>
							</div>
						</div>
					</Link>

					<Link
						to="/franchise"
						className="group relative overflow-hidden  shadow-lg"
					>
						<div
							className="aspect-[961/613] bg-cover bg-center transition-transform duration-300 group-hover:scale-110"
							style={{
								backgroundImage: `url(${BASE_URL}/media/guide_images/about2.png)`,
							}}
						/>
						<div className="absolute bottom-12 right-12 flex flex-col justify-end">
							<h3 className="text-white font-pretendard text-[30px] font-bold mb-2">
								창업문의
							</h3>
							<h1 className="font-mont text-white text-[50px] font-black mb-2">
								FRANCHISE
							</h1>
							<p className="text-white text-[20px]">
								봉매떡 매장유형과 가맹절차를 알아보세요.
							</p>
							<div className="flex justify-start">
								<button
									className="text-[12px] mt-4 px-12 py-2 rounded-full font-bold
									relative bg-primary text-white overflow-hidden
									transition-all duration-300 ease-in-out
									hover:bg-lightgray hover:text-primary
									before:content-['']
									before:absolute before:bottom-[-100%] before:left-[-100%]
									before:w-[300%] before:h-[300%]
									before:bg-primary
									before:rotate-45
									before:transition-transform before:duration-500 before:ease-out
									hover:before:translate-x-[35%] hover:before:translate-y-[-35%]
									[&>p]:relative [&>p]:z-10"
								>
									<p className="font-urbane text-[18.43px]">자세히보기</p>
								</button>
							</div>
						</div>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default About;
