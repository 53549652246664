import { BASE_URL } from "./apiConfig";

export const fetchPrivacyPolicy = async () => {
	try {
		console.log(BASE_URL);
		const response = await fetch(
			`${BASE_URL}/documents/api/legal-documents/privacy/`
		);
		if (!response.ok) throw new Error("Failed to fetch privacy policy");
		return await response.json();
	} catch (error) {
		console.error("Error fetching privacy policy:", error);
		throw error;
	}
};

export const fetchTermsOfService = async () => {
	try {
		const response = await fetch(
			`${BASE_URL}/documents/api/legal-documents/terms/`
		);
		if (!response.ok) throw new Error("Failed to fetch terms of service");
		return await response.json();
	} catch (error) {
		console.error("Error fetching terms of service:", error);
		throw error;
	}
};
