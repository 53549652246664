import { useState, useEffect, useRef } from "react";
import MenuCard from "./MenuCard";
import TitleAndMore from "./TitleAndMore";
import { fetchMenuCategories } from "../../services/menuService";

const FeaturedMenu = () => {
	const [signatureMenus, setSignatureMenus] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [scrollProgress, setScrollProgress] = useState(5);
	const scrollContainerRef = useRef(null);

	useEffect(() => {
		const loadSignatureMenus = async () => {
			try {
				const categories = await fetchMenuCategories();
				const allSignatureMenus = categories
					.flatMap((category) => category.menus)
					.filter((menu) => menu.is_signature);
				setSignatureMenus(allSignatureMenus);
			} catch (err) {
				setError("메뉴를 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadSignatureMenus();
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			if (!scrollContainerRef.current) return;

			const container = scrollContainerRef.current;
			const scrollLeft = container.scrollLeft;
			const scrollWidth = container.scrollWidth;
			const clientWidth = container.clientWidth;

			// 최대 스크롤 가능 거리
			const maxScroll = scrollWidth - clientWidth;

			// 스크롤이 없��� 경우 0을 반환
			if (maxScroll <= 0) {
				setScrollProgress(0);
				return;
			}

			// 스크롤 진행률 계산 수정
			const progress = (scrollLeft / maxScroll) * 100;
			setScrollProgress(Math.max(5, Math.min(progress, 100)));
		};

		const scrollContainer = scrollContainerRef.current;
		if (scrollContainer) {
			scrollContainer.addEventListener("scroll", handleScroll);
			handleScroll();
		}

		return () => {
			if (scrollContainer) {
				scrollContainer.removeEventListener("scroll", handleScroll);
			}
		};
	}, []);

	if (loading)
		return (
			<div className="flex justify-center items-center pt-[105px]">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);

	if (error)
		return (
			<div className="flex justify-center items-center pt-[105px] text-red-600">
				{error}
			</div>
		);

	return (
		<section className="pt-[105px] bg-white">
			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-16">
				<TitleAndMore title="Best Menu" to="/menu" />
			</div>

			<div className="relative w-full">
				<div
					ref={scrollContainerRef}
					className="flex overflow-x-auto scrollbar-hide mb-[52.6px] touch-pan-x"
					style={{
						scrollbarWidth: "none",
						msOverflowStyle: "none",
						WebkitOverflowScrolling: "touch",
						overscrollBehavior: "contain",
					}}
				>
					<div className="flex flex-row gap-[50px] ml-[max(16px,(100vw-1280px)/2+16px)] sm:ml-[max(24px,(100vw-1280px)/2+24px)] lg:ml-[max(32px,(100vw-1280px)/2+22px)]">
						{signatureMenus.map((item) => (
							<MenuCard
								key={item.id}
								title={item.name}
								engTitle={item.name_eng}
								imageUrl={item.image}
							/>
						))}
					</div>
				</div>

				<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
					<div className="h-[5.13px] bg-gray-200">
						<div
							className="h-full bg-gray500 transition-all duration-300 ease-out"
							style={{
								width: `${Math.max(scrollProgress, 5)}%`,
								transform: "translateZ(0)",
							}}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default FeaturedMenu;
