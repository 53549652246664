import Hero from "../components/home/Hero";
import FeaturedMenu from "../components/home/FeaturedMenu";
import About from "../components/home/About";
import Popup from "../components/home/Popup";
import HomeNews from "../components/home/HomeNews";
import SearchStoreSection from "../components/home/SearchStoreSection";
import Instagram from "../components/home/Instagram";
import TextBandEffect from "../components/home/TextBandEffect";
const Home = () => {
	return (
		<>
			<Popup />
			<Hero />
			<FeaturedMenu />
			<HomeNews />
			<About />
			<Instagram />
			<SearchStoreSection />
			<TextBandEffect />
		</>
	);
};

export default Home;
