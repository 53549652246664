import { Link, useLocation } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import mainTextLogo from "../../assets/images/main_text_logo.png";
import drawerTop from "../../assets/images/drawer_top.png";
import SocialLinks from "../common/SocialLinks";

const Navbar = () => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [drawerPosition, setDrawerPosition] = useState(0);
	const [logoHeight, setLogoHeight] = useState(49.7);
	const buttonRef = useRef(null);
	const location = useLocation();
	const [hoveredItem, setHoveredItem] = useState(null);

	const navItems = [
		{
			name: "브랜드",
			href: "/brand",
			subItems: [
				{ name: "브랜드 소개", href: "/brand" },
				{ name: "원재료 소개", href: "/brand/story" },
				{ name: "BI", href: "/brand/bi" },
			],
		},
		{
			name: "메뉴",
			href: "/menu",
			subItems: [
				{ name: "전체메뉴", href: "/menu" },
				{ name: "떡볶이", href: "/menu/tteokbokki" },
				{ name: "사이드", href: "/menu/side" },
				{ name: "토핑", href: "/menu/topping" },
				{ name: "세트", href: "/menu/set" },
			],
		},
		{
			name: "매장안내",
			href: "/locations",
			subItems: [{ name: "매장안내", href: "/locations" }],
		},
		{
			name: "새소식",
			href: "/news",
			subItems: [
				{ name: "프로모션", href: "/news/promotions" },
				{ name: "SNS", href: "/news/sns" },
			],
		},
	];

	const updateDrawerPosition = () => {
		if (buttonRef.current) {
			const rect = buttonRef.current.getBoundingClientRect();
			setDrawerPosition(rect.left);
		}
	};

	useEffect(() => {
		updateDrawerPosition();
		window.addEventListener("resize", updateDrawerPosition);
		return () => window.removeEventListener("resize", updateDrawerPosition);
	}, []);

	const toggleDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
		document.body.style.overflow = isDrawerOpen ? "unset" : "hidden";
	};

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			const maxScroll = 200; // 스크롤 효과가 완료되는 지점
			const minHeight = 32.6; // 최소 로고 높이
			const maxHeight = 49.7; // 최대 로고 높이

			// 스크롤 위치에 따라 로고 크기를 계산
			const newHeight = Math.max(
				minHeight,
				maxHeight - (scrollPosition / maxScroll) * (maxHeight - minHeight)
			);

			setLogoHeight(newHeight);
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<>
			<nav className="bg-primary text-white sticky top-0 z-50">
				{/* 통합 메뉴 패널 */}
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<div
						className="flex items-center justify-between"
						style={{ height: `${logoHeight * 2.5}px` }}
					>
						<div className="flex items-center space-x-4">
							{/* 햄버거 버튼 */}
							<button
								ref={buttonRef}
								onClick={toggleDrawer}
								className="inline-flex items-center justify-center rounded-md hover:bg-primary focus:outline-none md:hidden"
								style={{ padding: `${logoHeight * 0.1}px` }}
								aria-label="메인 메뉴"
							>
								<svg
									className="h-12 w-12"
									stroke="currentColor"
									fill="none"
									viewBox="0 0 24 24"
								>
									<>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="3"
											d="M4 6H20"
											stroke="#FFFFD4"
										/>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="3"
											d="M4 12H20"
											stroke="#FF8C8D"
										/>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="3"
											d="M4 18H20"
											stroke="#FFFFD4"
										/>
									</>
								</svg>
							</button>

							{/* 로고 */}
							<Link
								to="/"
								className="block transition-all duration-200 ml-0"
								aria-label="홈으로 이동"
							>
								<img
									src={mainTextLogo}
									alt="봉천동 매운 떡볶이"
									className="transition-all duration-200"
									style={{ height: `${logoHeight}px` }}
								/>
							</Link>
						</div>

						{/* 데스크톱 메뉴 */}
						<div className="hidden md:block">
							<div className="flex items-center justify-end space-x-12">
								{navItems.map((item) => (
									<div
										key={item.name}
										className="relative w-[100px]"
										onMouseEnter={() => setHoveredItem(item.name)}
										onMouseLeave={() => setHoveredItem(null)}
									>
										<Link
											to={item.href}
											className={`
												relative
												block
												text-center
												text-[18.5px] 
												font-medium 
												transition-colors
												duration-200
												
												group
												${
													location.pathname === item.href
														? "text-secondary"
														: "text-white hover:text-secondary"
												}
											`}
											aria-label={item.name}
										>
											{item.name}
											<span
												className={`
													absolute 
													left-0 
													bottom-0 
													w-0 
													h-[3px] 
													bg-secondary 
													transition-all 
													duration-300 
													group-hover:w-full
													${location.pathname === item.href ? "w-full" : ""}
												`}
											/>
										</Link>
									</div>
								))}
								<Link
									to="/family"
									className="relative group px-6 py-2 bg-secondary text-primary rounded-full text-lg font-medium hover:bg-secondary/90 transition-colors duration-200"
									aria-label="창업 문의하기"
								>
									창업문의
									<span className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-2 h-2 bg-secondary rotate-45 group-hover:bg-secondary/90 transition-colors duration-200" />
								</Link>
							</div>
						</div>
					</div>
				</div>

				{/* 통합 서브메뉴 패널 */}
				<div
					className={`absolute left-0 right-0 bg-primary transition-all duration-300 border-t-[6px] border-secondary ${
						hoveredItem ? "opacity-100 visible" : "opacity-0 invisible"
					}`}
					onMouseEnter={() => setHoveredItem(hoveredItem)}
					onMouseLeave={() => setHoveredItem(null)}
				>
					<div className="max-w-7xl px-4 sm:px-6 lg:px-8 mx-auto flex items-center justify-between">
						<div className="flex items-center space-x-4">
							{/* 로고 */}
							<Link
								to="/"
								className="block transition-all duration-200 invisible"
								aria-label="홈으로 이동"
							>
								<img
									src={mainTextLogo}
									alt="봉천동 매운 떡볶이"
									className="transition-all duration-200"
									style={{ height: `${logoHeight}px` }}
								/>
							</Link>
						</div>

						{/* 서브 메뉴 */}
						<div className="hidden md:block">
							<div className="flex space-x-12">
								{navItems.map((item) => (
									<div
										key={item.name}
										className="py-4"
										style={{ width: "100px" }}
										onMouseEnter={() => setHoveredItem(item.name)}
										onMouseLeave={() => setHoveredItem(null)}
									>
										{item.subItems.length > 0 && (
											<ul className="space-y-3">
												{item.subItems.map((subItem) => (
													<li key={subItem.name}>
														<Link
															to={subItem.href}
															className={`
																relative
																block
																text-center
																text-[16px]
																font-medium
																transition-colors
																duration-200
																group
																${
																	location.pathname === subItem.href
																		? "text-secondary"
																		: "text-white hover:text-secondary"
																}
															`}
														>
															{subItem.name}
															<span
																className={`
																	absolute 
																	left-0 
																	bottom-0 
																	w-0 
																	h-[1px] 
																	bg-secondary 
																	transition-all 
																	duration-300 
																	group-hover:w-full
																	${location.pathname === subItem.href ? "w-full" : ""}
																`}
															/>
														</Link>
													</li>
												))}
											</ul>
										)}
									</div>
								))}
								<Link
									to="/family"
									className="invisible relative group px-6 py-2 bg-secondary text-primary rounded-full text-lg font-medium hover:bg-secondary/90 transition-colors duration-200"
									aria-label="창업 문의하기"
								>
									창업문의
									<span className="absolute -bottom-1 left-1/2 -translate-x-1/2 w-2 h-2 bg-secondary rotate-45 group-hover:bg-secondary/90 transition-colors duration-200" />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</nav>

			{/* 모바일 드로어 */}
			<div
				className={`fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ${
					isDrawerOpen ? "opacity-100" : "opacity-0 pointer-events-none"
				}`}
				onClick={toggleDrawer}
			>
				<div
					className={`absolute text-white top-[100px] w-[300px] bg-red-600 transform transition-transform duration-300 ease-in-out rounded-b-lg ${
						isDrawerOpen ? "translate-y-0" : "-translate-y-full"
					}`}
					style={{ left: `${Math.max(drawerPosition - 230, 18)}px` }}
					onClick={(e) => e.stopPropagation()}
				>
					<div className="flex flex-col pt-[100px] px-6 pb-6">
						{navItems.map((item) => (
							<Link
								key={item.name}
								to={item.href}
								className={`block py-2 text-2xl font-medium hover:text-accent ${
									location.pathname === item.href ? "text-accent" : ""
								}`}
								onClick={toggleDrawer}
							>
								{item.name}
							</Link>
						))}
						<div className="pt-6 border-t border-primary">
							<Link
								to="/family"
								className="block py-1 text-md font-medium text-secondary"
							>
								가맹안내
							</Link>
							<Link
								to="/company"
								className="block py-1 text-md font-medium text-secondary"
							>
								(주)스파이시즘
							</Link>
						</div>
						<div className="mt-8 flex justify-between items-center">
							<SocialLinks color="white" />
							<button
								onClick={toggleDrawer}
								className="text-white text-2xl font-medium hover:text-secondary focus:outline-none"
								aria-label="닫기"
							>
								<svg
									className="h-8 w-8"
									stroke="currentColor"
									fill="none"
									viewBox="0 0 24 24"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="3"
										d="M4 4l16 16m-16 0l16-16"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
				<img
					src={drawerTop}
					alt="drawer top"
					className="absolute top-[110px] left-0 w-[335px]"
					style={{ left: `${Math.max(drawerPosition - 230 - 18, 0)}px` }}
				/>
			</div>
		</>
	);
};

export default Navbar;
