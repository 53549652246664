import { BASE_URL } from "./apiConfig";

export const fetchRegions = async () => {
	try {
		const response = await fetch(`${BASE_URL}/franchise/api/regions`);
		if (!response.ok) throw new Error("Failed to fetch regions");
		return await response.json();
	} catch (error) {
		console.error("Error fetching regions:", error);
		throw error;
	}
};
