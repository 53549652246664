import { Link } from "react-router-dom";

const TitleAndMore = ({ title, to }) => {
	return (
		<div className="flex flex-row justify-between items-center mb-12">
			<h2 className="text-primary text-[47px] font-mont font-black text-start">
				{title}
			</h2>
			<Link to={to} className="flex items-center gap-1">
				<button
					className="text-[12px] mt-4 px-8 py-2 rounded-full font-bold
						relative bg-primary text-white overflow-hidden
						transition-all duration-300 ease-in-out
						hover:bg-lightgray hover:text-primary
						before:content-['']
						before:absolute before:bottom-[-100%] before:left-[-100%]
						before:w-[300%] before:h-[300%]
						before:bg-primary
						before:rotate-45
						before:transition-transform before:duration-500 before:ease-out
						hover:before:translate-x-[35%] hover:before:translate-y-[-35%]
						[&>p]:relative [&>p]:z-10"
				>
					<p className="font-urbane text-[12px]">MORE &nbsp; +</p>
				</button>
			</Link>
		</div>
	);
};

export default TitleAndMore;
