import React from "react";

const MenuCard = ({ title, engTitle, imageUrl }) => {
	return (
		<div className="mx-auto transition-all duration-300 ease-out hover:opacity-80">
			<div className="flex flex-col items-center">
				{/* 이미지 컨테이너 */}
				<div className="w-[291.4px] h-[219.17px] relative overflow-hidden">
					<img
						src={imageUrl}
						alt={title}
						className="w-full h-full object-fill transition-transform duration-300 ease-out hover:scale-110"
					/>
				</div>

				{/* 텍스트 컨테이너 */}
				<div className="flex flex-col items-center pt-[17.75px] space-y-[5.47px]">
					<h4 className="text-[22.73px] text-primaryText font-bold text-center tracking-[-0.8px] font-pretendard">
						{title}
					</h4>
					<p className="text-[14.77px] text-gray600 font-medium text-center tracking-[0.67px] leading-[19.5px]">
						{engTitle}
					</p>
				</div>
			</div>
		</div>
	);
};

export default MenuCard;
