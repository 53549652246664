import { BASE_URL } from "./apiConfig";

export const fetchCopy = async (code) => {
	try {
		const response = await fetch(`${BASE_URL}/documents/api/copies/${code}/`);
		if (!response.ok) throw new Error(`Failed to fetch copy for code: ${code}`);
		return await response.json();
	} catch (error) {
		console.error(`Error fetching copy for code ${code}:`, error);
		throw error;
	}
};

export const fetchCopies = async () => {
	const response = await fetch(`${BASE_URL}/documents/api/copies/`);
	if (!response.ok) throw new Error("Failed to fetch copies");
	return await response.json();
};
