import "./TextBandEffect.css";

const TextBandEffect = () => {
	return (
		<div className="relative w-full h-[306px] overflow-hidden flex items-center justify-center">
			<div className="absolute flex whitespace-nowrap animate-marquee">
				<p className="font-mont text-[95px] font-black text-primary mx-4">
					BONGCHEONDONG SPICY TTEOKBOKKI ∙
				</p>
				<p className="font-mont text-[95px] font-black text-outline mx-4">
					BONGCHEONDONG SPICY TTEOKBOKKI ∙
				</p>
				<p className="font-mont text-[95px] font-black text-primary mx-4">
					BONGCHEONDONG SPICY TTEOKBOKKI ∙
				</p>
				<p className="font-mont text-[95px] font-black text-outline mx-4">
					BONGCHEONDONG SPICY TTEOKBOKKI ∙
				</p>
			</div>
		</div>
	);
};

export default TextBandEffect;
