import { BASE_URL } from "./apiConfig";

export const fetchBanners = async () => {
	try {
		const response = await fetch(`${BASE_URL}/marketing/api/banners/`);
		if (!response.ok) throw new Error("Failed to fetch banners");
		return await response.json();
	} catch (error) {
		console.error("Error fetching banners:", error);
		throw error;
	}
};
