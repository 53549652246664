const Brand = () => {
	const histories = [
		{
			year: "1992",
			events: ["봉천동 1호점 오픈", "비법 소스 레시피 개발"],
		},
		{
			year: "2000",
			events: ["법인 설립", "가맹사업 시작", "강남점 오픈"],
		},
		{
			year: "2010",
			events: [
				"100호점 달성",
				"식품안전경영시스템 인증",
				"올해의 프랜차이즈 대상 수상",
			],
		},
		{
			year: "2020",
			events: [
				"250호점 달성",
				"모바일 주문 시스템 도입",
				"배달 전문 매장 확장",
			],
		},
		{
			year: "2024",
			events: ["글로벌 진출 시작", "ESG 경영 선포", "신규 브랜드 런칭"],
		},
	];

	return (
		<div className="py-16 bg-gray-50">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				{/* 브랜드 소개 섹션 */}
				<section className="mb-32" id="overview">
					<h2 className="text-4xl font-bold text-center mb-12">브랜드 소개</h2>
					<div className="bg-white rounded-lg shadow-lg p-8">
						<div className="prose max-w-none">
							<p className="text-lg text-gray-600 mb-8">
								봉천동 매운 떡볶이는 1992년 서울 봉천동에서 시작하여 30년 동안
								한결같은 맛을 지켜온 대한민국 대표 떡볶이 브랜드입니다.
							</p>
							<div className="mb-8">
								<img
									src="/images/brand/overview.jpg"
									alt="봉천동 매운 떡볶이 매장"
									className="w-full rounded-lg"
								/>
							</div>
							<h3 className="text-2xl font-bold mb-4">브랜드 철학</h3>
							<p className="text-gray-600 mb-8">
								우리는 '정직한 맛'을 최우선 가치로 삼고 있습니다. 엄선된 재료와
								30년간 연구해온 비법 소스로 만드는 떡볶이는 남녀노소 누구나
								좋아하는 대한민국의 대표 분식으로 자리잡았습니다.
							</p>
							<h3 className="text-2xl font-bold mb-4">브랜드 가치</h3>
							<ul className="list-disc list-inside text-gray-600 mb-8">
								<li>정직한 맛 - 변함없는 맛과 품질</li>
								<li>신선한 재료 - 매일 공급되는 신선한 식자재</li>
								<li>위생 관리 - 철저한 위생 관리 시스템</li>
								<li>고객 만족 - 친절한 서비스와 깨끗한 매장 관리</li>
							</ul>
						</div>
					</div>
				</section>

				{/* CEO 인사말 섹션 */}
				<section className="mb-32" id="ceo">
					<h2 className="text-4xl font-bold text-center mb-12">CEO 인사말</h2>
					<div className="bg-white rounded-lg shadow-lg p-8">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center mb-8">
							<div>
								<img
									src="/images/brand/ceo.jpg"
									alt="CEO"
									className="rounded-lg"
								/>
							</div>
							<div>
								<h3 className="text-2xl font-bold mb-4">
									"맛있는 떡볶이로 세계를 행복하게"
								</h3>
								<p className="text-gray-600 mb-4">
									안녕하십니까, 봉천동 매운 떡볶이 대표 홍길동입니다.
								</p>
								<p className="text-gray-600">
									1992년 봉천동의 작은 가게에서 시작한 우리의 도전은 이제 전국
									250개 매장을 통해 더 많은 고객님들과 만나고 있습니다.
								</p>
							</div>
						</div>
						<div className="prose max-w-none text-gray-600">
							<p className="mb-4">
								우리는 앞으로도 변함없는 맛과 품질로 고객님들께 보답하겠습니다.
								한국의 맛을 세계에 알리는 글로벌 브랜드로 성장하기 위해
								노력하겠습니다.
							</p>
							<p className="text-right mt-8">
								봉천동 매운 떡볶이
								<br />
								대표이사 홍길동
							</p>
						</div>
					</div>
				</section>

				{/* 연혁 섹션 */}
				<section className="mb-32" id="history">
					<h2 className="text-4xl font-bold text-center mb-12">연혁</h2>
					<div className="bg-white rounded-lg shadow-lg p-8">
						<div className="space-y-12">
							{histories.map((history) => (
								<div
									key={history.year}
									className="flex flex-col md:flex-row gap-8"
								>
									<div className="md:w-1/4">
										<h3 className="text-3xl font-bold text-red-600">
											{history.year}
										</h3>
									</div>
									<div className="md:w-3/4">
										<ul className="space-y-4">
											{history.events.map((event, index) => (
												<li key={index} className="flex items-start">
													<div className="w-2 h-2 bg-red-600 rounded-full mt-2 mr-4"></div>
													<span className="text-gray-600">{event}</span>
												</li>
											))}
										</ul>
									</div>
								</div>
							))}
						</div>
					</div>
				</section>

				{/* CI/BI 섹션 */}
				<section id="ci">
					<h2 className="text-4xl font-bold text-center mb-12">CI / BI</h2>
					<div className="bg-white rounded-lg shadow-lg p-8">
						{/* 로고 섹션 */}
						<section className="mb-16">
							<h3 className="text-2xl font-bold mb-8">로고</h3>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
								<div>
									<h4 className="text-lg font-bold mb-4">기본형</h4>
									<div className="border rounded-lg p-8 bg-gray-50">
										<img
											src="/images/brand/logo.png"
											alt="봉천동 매운 떡볶이 로고"
											className="max-w-full h-auto"
										/>
									</div>
								</div>
								<div>
									<h4 className="text-lg font-bold mb-4">심볼마크</h4>
									<div className="border rounded-lg p-8 bg-gray-50">
										<img
											src="/images/brand/symbol.png"
											alt="봉천동 매운 떡볶이 심볼"
											className="max-w-full h-auto"
										/>
									</div>
								</div>
							</div>
						</section>

						{/* 컬러 시스템 */}
						<section className="mb-16">
							<h3 className="text-2xl font-bold mb-8">컬러 시스템</h3>
							<div className="grid grid-cols-2 md:grid-cols-4 gap-4">
								<div>
									<div className="h-24 bg-red-600 rounded-t-lg"></div>
									<div className="border border-t-0 rounded-b-lg p-4">
										<p className="font-bold">Primary Red</p>
										<p className="text-sm text-gray-600">#DC2626</p>
									</div>
								</div>
								<div>
									<div className="h-24 bg-yellow-400 rounded-t-lg"></div>
									<div className="border border-t-0 rounded-b-lg p-4">
										<p className="font-bold">Secondary Yellow</p>
										<p className="text-sm text-gray-600">#FBBF24</p>
									</div>
								</div>
							</div>
						</section>

						{/* 사용 규정 */}
						<section>
							<h3 className="text-2xl font-bold mb-8">사용 규정</h3>
							<div className="prose max-w-none text-gray-600">
								<p>
									로고는 브랜드의 핵심 구성 요소이므로 규정된 형태와 색상으로
									사용해야 합니다. 임의로 변형하거나 왜곡하여 사용할 수
									없습니다.
								</p>
							</div>
						</section>
					</div>
				</section>
			</div>
		</div>
	);
};

export default Brand;
