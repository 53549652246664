import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { fetchBanners } from "../../services/bannerService";
import IconLogo from "../../assets/images/icon_logo_secondary.png";

const Hero = () => {
	const navigate = useNavigate();
	const [banners, setBanners] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const loadBanners = async () => {
			try {
				const data = await fetchBanners();
				setBanners(data);
			} catch (err) {
				setError("배너를 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadBanners();
	}, []);

	const nextSlide = useCallback(() => {
		setCurrentIndex((prevIndex) =>
			prevIndex === banners.length - 1 ? 0 : prevIndex + 1
		);
	}, [banners.length]);

	useEffect(() => {
		if (banners.length > 1) {
			const timer = setInterval(nextSlide, 5000);
			return () => clearInterval(timer);
		}
	}, [nextSlide, banners.length]);

	const prevSlide = () => {
		setCurrentIndex((prevIndex) =>
			prevIndex === 0 ? banners.length - 1 : prevIndex - 1
		);
	};

	const handleBannerClick = (banner) => {
		if (banner.link) {
			window.location.href = banner.link;
		} else if (banner.post) {
			navigate(`/news/${banner.post}`);
		}
	};

	if (loading)
		return (
			<div className="relative w-full pb-[33.67%] bg-gray-100">
				<div className="absolute inset-0 flex justify-center items-center">
					<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
				</div>
			</div>
		);

	if (error)
		return (
			<div className="relative w-full pb-[33.67%] bg-gray-100">
				<div className="absolute inset-0 flex justify-center items-center text-red-600">
					{error}
				</div>
			</div>
		);

	if (banners.length === 0) return null;

	const currentBanner = banners[currentIndex];

	return (
		<div className="relative w-full pb-[33.67%] bg-white">
			{/* 배너 이미지 */}
			{banners.map((banner, index) => (
				<div
					key={banner.id}
					className={`absolute inset-0 transition-opacity duration-1000 ${
						index === currentIndex
							? "opacity-100"
							: "opacity-0 pointer-events-none"
					}`}
				>
					<div
						className="absolute inset-0 bg-cover bg-center"
						style={{ backgroundImage: `url(${banner.image})` }}
					>
						<div className="absolute inset-0 " />
					</div>
				</div>
			))}
			{/* 점 표시기 추가 */}

			<div className="absolute bg-primary/70 bottom-4 left-1/2 -translate-x-1/2 flex justify-center gap-2 items-center px-6 py-2 rounded-full">
				{banners.map((_, index) => (
					<button
						key={index}
						onClick={() => setCurrentIndex(index)}
						onKeyDown={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								setCurrentIndex(index);
							}
						}}
						className="w-[30px] h-auto rounded-full transition-colors"
						aria-label={`슬라이드 ${index + 1}로 이동`}
						tabIndex={0}
					>
						{currentIndex === index ? (
							<img
								src={IconLogo}
								alt="Current Slide"
								className="w-full h-full"
							/>
						) : (
							<div className="w-[30px] h-[30px] flex justify-center items-center">
								<div className="w-4 h-4 bg-white hover:bg-primary/50 rounded-full" />
							</div>
						)}
					</button>
				))}
			</div>
		</div>
	);
};

export default Hero;
