import { useState, useEffect } from "react";
import { fetchTermsOfService } from "../services/documentService";

const TermsOfService = () => {
	const [terms, setTerms] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const loadTerms = async () => {
			try {
				const data = await fetchTermsOfService();
				setTerms(data);
			} catch (err) {
				setError("이용약관을 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadTerms();
	}, []);

	if (loading)
		return (
			<div className="flex justify-center items-center min-h-screen">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);

	if (error)
		return (
			<div className="flex justify-center items-center min-h-screen text-red-600">
				{error}
			</div>
		);

	return (
		<div className="py-16 bg-gray-50 min-h-screen">
			<div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className="bg-white rounded-lg shadow-lg overflow-hidden">
					{/* 헤더 섹션 */}
					<div className="p-8 border-b border-gray-200">
						<h1 className="text-3xl font-bold text-center text-gray-900">
							{terms?.title}
						</h1>
						<p className="text-center text-gray-500 mt-2">
							최종 수정일: {new Date(terms?.updated_at).toLocaleDateString()}
						</p>
					</div>

					{/* 컨텐츠 섹션 */}
					<div
						className="prose prose-lg max-w-none p-8 prose-headings:text-gray-900 prose-p:text-gray-600 prose-a:text-red-600 prose-strong:text-gray-900 prose-ul:text-gray-600"
						dangerouslySetInnerHTML={{ __html: terms?.content }}
					/>
				</div>
			</div>
		</div>
	);
};

export default TermsOfService;
