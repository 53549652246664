import { BASE_URL } from "./apiConfig";

export const fetchPosts = async (page = 1, category = "") => {
	try {
		const response = await fetch(
			`${BASE_URL}/marketing/api/posts/news_and_promotion/?page=${page}${
				category ? `&category=${encodeURIComponent(category)}` : ""
			}`
		);
		if (!response.ok) throw new Error("Failed to fetch posts");
		return await response.json();
	} catch (error) {
		console.error("Error fetching posts:", error);
		throw error;
	}
};

export const fetchPostDetail = async (id) => {
	try {
		const response = await fetch(`${BASE_URL}/marketing/api/posts/${id}/`);
		if (!response.ok) throw new Error("Failed to fetch post detail");
		return await response.json();
	} catch (error) {
		console.error("Error fetching post detail:", error);
		throw error;
	}
};
