import { BASE_URL } from "./apiConfig";
const fetchInstagramPosts = async () => {
	try {
		const response = await fetch(`${BASE_URL}/marketing/api/instagrams/`);
		if (!response.ok) throw new Error("Failed to fetch instagram posts");
		return await response.json();
	} catch (error) {
		console.error("Error fetching popups:", error);
		throw error;
	}
};

export default fetchInstagramPosts;
