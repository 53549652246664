import { create } from "zustand";
import { fetchCopy } from "../services/copyService";

const useCopyStore = create((set) => ({
	// 상태
	copies: {},
	loading: false,
	error: null,

	// 액션
	getCopy: async (code) => {
		try {
			set({ loading: true, error: null });

			// 이미 해당 코드의 카피가 있는지 확인
			const existingCopy = useCopyStore.getState().copies[code];
			if (existingCopy) return existingCopy;

			// 없으면 새로 fetch
			const copyData = await fetchCopy(code);

			set((state) => ({
				copies: {
					...state.copies,
					[code]: copyData,
				},
				loading: false,
			}));

			return copyData;
		} catch (error) {
			set({
				error: error.message,
				loading: false,
			});
			throw error;
		}
	},

	// 카피 초기화
	resetCopies: () => {
		set({ copies: {}, error: null });
	},

	// 특정 코드의 카피만 초기화
	resetCopy: (code) => {
		set((state) => {
			const newCopies = { ...state.copies };
			delete newCopies[code];
			return { copies: newCopies };
		});
	},
}));

export default useCopyStore;
