import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchPosts } from "../services/newsService";

const News = () => {
	const navigate = useNavigate();
	const [allPosts, setAllPosts] = useState([]);
	const [filteredPosts, setFilteredPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [selectedCategory, setSelectedCategory] = useState("공지사항");
	const [visiblePromotionCount, setVisiblePromotionCount] = useState(9);

	const categories = ["공지사항", "프로모션&이벤트", "뉴스&광고"];
	const POSTS_PER_PAGE = 10;
	const PROMOTIONS_PER_LOAD = 9;

	useEffect(() => {
		const loadPosts = async () => {
			try {
				setLoading(true);
				const data = await fetchPosts();
				setAllPosts(data.results || data);
				const initialFiltered = (data.results || data).filter(
					(post) => post.category === "공지사항"
				);
				setFilteredPosts(initialFiltered);
				setTotalPages(Math.ceil(initialFiltered.length / POSTS_PER_PAGE));
			} catch (err) {
				console.error("게시글 로딩 에러:", err);
				setError("게시글을 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadPosts();
	}, []);

	useEffect(() => {
		const filtered = allPosts.filter(
			(post) => post.category === selectedCategory
		);
		setFilteredPosts(filtered);
		setTotalPages(
			selectedCategory === "프로모션&이벤트"
				? 1
				: Math.ceil(filtered.length / POSTS_PER_PAGE)
		);
		setCurrentPage(1);
	}, [selectedCategory, allPosts]);

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
		window.scrollTo(0, 0);
	};

	const getCurrentPagePosts = () => {
		if (selectedCategory === "프로모션&이벤트") {
			return filteredPosts.slice(0, visiblePromotionCount);
		}
		const startIndex = (currentPage - 1) * POSTS_PER_PAGE;
		const endIndex = startIndex + POSTS_PER_PAGE;
		return filteredPosts.slice(startIndex, endIndex);
	};

	const handleLoadMore = () => {
		setVisiblePromotionCount((prev) => prev + PROMOTIONS_PER_LOAD);
	};

	const NoPostsMessage = ({ category }) => (
		<div className="flex flex-col items-center justify-center py-16 text-gray-500">
			<svg
				className="w-16 h-16 mb-4"
				fill="none"
				stroke="currentColor"
				viewBox="0 0 24 24"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
				/>
			</svg>
			<p className="text-lg font-medium mb-2">
				아직 작성된 {category} 글이 없습니다.
			</p>
			<p>새로운 {category}가 등록되면 이곳에서 확인하실 수 있습니다.</p>
		</div>
	);

	if (loading)
		return (
			<div className="flex justify-center items-center min-h-screen">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);

	if (error)
		return (
			<div className="flex justify-center items-center min-h-screen text-red-600">
				{error}
			</div>
		);

	return (
		<div className="py-16 bg-gray-50">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<h1 className="text-4xl font-bold text-center mb-12">소식</h1>

				{/* 카테고리 필터 */}
				<div className="flex justify-center mb-8">
					<div className="inline-flex rounded-lg border border-gray-200 bg-white p-1">
						{categories.map((category) => (
							<button
								key={category}
								onClick={() => setSelectedCategory(category)}
								className={`px-4 py-2 rounded-md ${
									selectedCategory === category
										? "bg-red-600 text-white"
										: "text-gray-600 hover:text-red-600"
								}`}
							>
								{category}
							</button>
						))}
					</div>
				</div>

				{filteredPosts.length === 0 ? (
					<div className="bg-white rounded-lg shadow-lg">
						<NoPostsMessage category={selectedCategory} />
					</div>
				) : selectedCategory === "프로모션&이벤트" ? (
					<div>
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
							{getCurrentPagePosts().map((post) => (
								<div
									key={post.id}
									className="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer transform hover:scale-105 transition-transform duration-200"
									onClick={() => navigate(`/news/${post.id}`)}
								>
									<div className="aspect-w-16 aspect-h-9">
										<img
											src={post.banner_image || post.image}
											alt={post.title}
											className="w-full h-full object-cover"
										/>
									</div>
									<div className="p-4">
										<h3 className="font-bold text-lg mb-2 line-clamp-1">
											{post.title}
										</h3>
										<p className="text-sm text-gray-500">
											{new Date(post.created_at).toLocaleDateString()}
										</p>
									</div>
								</div>
							))}
						</div>
						{/* 더보기 버튼 */}
						{visiblePromotionCount < filteredPosts.length && (
							<div className="flex justify-center mt-8">
								<button
									onClick={handleLoadMore}
									className="bg-white border border-red-600 text-red-600 hover:bg-red-50 px-6 py-3 rounded-lg transition-colors duration-200 flex items-center space-x-2"
								>
									<span>더보기</span>
									<svg
										className="w-5 h-5"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M19 9l-7 7-7-7"
										/>
									</svg>
								</button>
							</div>
						)}
					</div>
				) : (
					// 일반 게시판 레이아웃
					<div className="bg-white rounded-lg shadow-lg overflow-hidden">
						<table className="min-w-full divide-y divide-gray-200">
							<thead className="bg-gray-50">
								<tr>
									<th
										scope="col"
										className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24"
									>
										카테고리
									</th>
									<th
										scope="col"
										className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
									>
										제목
									</th>
									<th
										scope="col"
										className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
									>
										작성일
									</th>
								</tr>
							</thead>
							<tbody className="bg-white divide-y divide-gray-200">
								{getCurrentPagePosts().map((post) => (
									<tr
										key={post.id}
										className="hover:bg-gray-50 cursor-pointer"
										onClick={() => navigate(`/news/${post.id}`)}
									>
										<td className="px-6 py-4 whitespace-nowrap">
											<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
												{post.category}
											</span>
										</td>
										<td className="px-6 py-4">
											<div className="text-sm text-gray-900">{post.title}</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
											{new Date(post.created_at).toLocaleDateString()}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}

				{/* 페이지네이션 (프로모션&이벤트 제외이면서 게시글이 있을 때만 표시) */}
				{selectedCategory !== "프로모션&이벤트" && filteredPosts.length > 0 && (
					<div className="flex justify-center mt-8">
						<nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
							<button
								onClick={() => handlePageChange(currentPage - 1)}
								disabled={currentPage === 1}
								className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium ${
									currentPage === 1
										? "text-gray-300"
										: "text-gray-500 hover:bg-gray-50"
								}`}
							>
								이전
							</button>
							{[...Array(totalPages)].map((_, i) => (
								<button
									key={i + 1}
									onClick={() => handlePageChange(i + 1)}
									className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
										currentPage === i + 1
											? "z-10 bg-red-50 border-red-500 text-red-600"
											: "bg-white text-gray-500 hover:bg-gray-50"
									}`}
								>
									{i + 1}
								</button>
							))}
							<button
								onClick={() => handlePageChange(currentPage + 1)}
								disabled={currentPage === totalPages}
								className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium ${
									currentPage === totalPages
										? "text-gray-300"
										: "text-gray-500 hover:bg-gray-50"
								}`}
							>
								다음
							</button>
						</nav>
					</div>
				)}
			</div>
		</div>
	);
};

export default News;
