import { useState, useEffect } from "react";
import { Container as MapDiv, NaverMap, Marker } from "react-naver-maps";
import { fetchRegions } from "../services/storeService";

const Locations = () => {
	const [regions, setRegions] = useState([]);
	const [selectedRegion, setSelectedRegion] = useState("전체");
	const [searchKeyword, setSearchKeyword] = useState("");
	const [selectedStore, setSelectedStore] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const loadRegions = async () => {
			try {
				const data = await fetchRegions();
				setRegions(data);
			} catch (err) {
				setError("매장 정보를 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadRegions();
	}, []);

	// 모든 매장 데이터를 하나의 배열로 변환
	const allStores = regions.flatMap((region) =>
		region.stores.map((store) => ({
			...store,
			regionName: region.name,
		}))
	);

	const filteredStores = allStores.filter((store) => {
		const matchesRegion =
			selectedRegion === "전체" || store.regionName === selectedRegion;
		const matchesSearch =
			store.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
			store.address.toLowerCase().includes(searchKeyword.toLowerCase());
		return matchesRegion && matchesSearch;
	});

	const handleMarkerClick = (store) => {
		setSelectedStore(store);
	};

	if (loading)
		return (
			<div className="flex justify-center items-center min-h-screen">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);

	if (error)
		return (
			<div className="flex justify-center items-center min-h-screen text-red-600">
				{error}
			</div>
		);

	return (
		<div className="py-16 bg-gray-50">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<h1 className="text-4xl font-bold text-center mb-12">매장찾기</h1>

				{/* 검색 필터 */}
				<div className="bg-white rounded-lg shadow-lg p-6 mb-8">
					<div className="flex flex-wrap gap-4 items-center justify-between">
						<div className="flex gap-4">
							<button
								onClick={() => setSelectedRegion("전체")}
								className={`px-4 py-2 rounded-md ${
									selectedRegion === "전체"
										? "bg-red-600 text-white"
										: "bg-gray-100 text-gray-700 hover:bg-gray-200"
								}`}
							>
								전체
							</button>
							{regions.map((region) => (
								<button
									key={region.id}
									onClick={() => setSelectedRegion(region.name)}
									className={`px-4 py-2 rounded-md ${
										selectedRegion === region.name
											? "bg-red-600 text-white"
											: "bg-gray-100 text-gray-700 hover:bg-gray-200"
									}`}
								>
									{region.name}
								</button>
							))}
						</div>
						<div className="flex-1 max-w-md">
							<input
								type="text"
								placeholder="매장명 또는 주소 검색"
								value={searchKeyword}
								onChange={(e) => setSearchKeyword(e.target.value)}
								className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
							/>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
					{/* 매장 목록 */}
					<div className="lg:col-span-1 bg-white rounded-lg shadow-lg p-6 h-[600px] overflow-y-auto">
						<h2 className="text-xl font-bold mb-4">
							매장 목록 ({filteredStores.length})
						</h2>
						<div className="space-y-4">
							{filteredStores.map((store) => (
								<div
									key={store.id}
									className={`border-b border-gray-200 last:border-0 pb-4 cursor-pointer ${
										selectedStore?.id === store.id
											? "bg-red-50 p-2 rounded"
											: ""
									}`}
									onClick={() => handleMarkerClick(store)}
								>
									<h3 className="font-bold text-lg mb-2">
										봉천동 매운 떡볶이 {store.name}
									</h3>
									<p className="text-gray-600 mb-1">{store.address}</p>
									<p className="text-gray-600">{store.phone}</p>
								</div>
							))}
						</div>
					</div>

					{/* 네이버 지도 */}
					<div className="lg:col-span-2 bg-white rounded-lg shadow-lg overflow-hidden">
						<MapDiv
							style={{
								width: "100%",
								height: "600px",
							}}
						>
							<NaverMap
								defaultCenter={
									new window.naver.maps.LatLng(
										selectedStore?.lat || 37.5665,
										selectedStore?.lng || 126.978
									)
								}
								defaultZoom={13}
								options={{
									suppressInfoWindows: true,
									trackingOptOut: true,
									cookieEnabled: false,
									scaleControl: false,
									logoControl: false,
									mapDataControl: false,
									zoomControl: true,
									minZoom: 6,
									maxZoom: 21,
								}}
							>
								{filteredStores.map((store) => (
									<Marker
										key={store.id}
										position={
											new window.naver.maps.LatLng(store.lat, store.lng)
										}
										onClick={() => handleMarkerClick(store)}
										animation={selectedStore?.id === store.id ? 1 : 0}
										options={{
											clickable: true,
											hasInfoWindow: false,
										}}
									/>
								))}
							</NaverMap>
						</MapDiv>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Locations;
