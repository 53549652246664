import { useState, useEffect } from "react";
import { fetchPosts } from "../../services/newsService";
import NewsCard from "./NewsCard";
import TitleAndMore from "./TitleAndMore";

const HomeNews = () => {
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const loadPosts = async () => {
			try {
				setLoading(true);
				const data = await fetchPosts();
				setPosts((data.results || data).slice(0, 4));
			} catch (err) {
				console.error("게시글 로딩 에러:", err);
				setError("게시글을 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadPosts();
	}, []);

	if (loading)
		return (
			<div className="flex justify-center items-center pt-[105px]">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);

	if (error)
		return (
			<div className="flex justify-center items-center pt-[105px] text-red-600">
				{error}
			</div>
		);

	return (
		<section className="pt-[105px] bg-white">
			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-16">
				<TitleAndMore title="News" to="/news" />
			</div>

			<div className="relative w-full">
				<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-[50px]">
						{posts.map((post) => (
							<NewsCard key={post.id} post={post} />
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default HomeNews;
