import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchPostDetail } from "../services/newsService";

const NewsDetail = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [post, setPost] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const loadPost = async () => {
			try {
				setLoading(true);
				const data = await fetchPostDetail(id);
				setPost(data);
			} catch (err) {
				setError("게시글을 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadPost();
	}, [id]);

	if (loading)
		return (
			<div className="flex justify-center items-center min-h-screen">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);

	if (error)
		return (
			<div className="flex justify-center items-center min-h-screen text-red-600">
				{error}
			</div>
		);

	return (
		<div className="py-16 bg-gray-50">
			<div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className="bg-white rounded-lg shadow-lg overflow-hidden">
					{/* 배너 이미지 */}
					{post?.banner_image && (
						<div className="w-full">
							<img
								src={post.banner_image}
								alt={post.title}
								className="w-full h-auto"
							/>
						</div>
					)}

					{/* 헤더 */}
					<div className="p-6 border-b border-gray-200">
						<div className="flex items-center justify-between mb-4">
							<span className="px-3 py-1 text-sm font-semibold text-red-800 bg-red-100 rounded-full">
								{post?.category}
							</span>
							<span className="text-sm text-gray-500">
								{new Date(post?.created_at).toLocaleDateString()}
							</span>
						</div>
						<h1 className="text-2xl font-bold text-gray-900">{post?.title}</h1>
					</div>

					{/* 본문 */}
					<div className="p-6">
						<div
							className="prose max-w-none"
							dangerouslySetInnerHTML={{ __html: post?.content }}
						/>
					</div>

					{/* 하단 버튼 */}
					<div className="p-6 bg-gray-50 border-t border-gray-200">
						<button
							onClick={() => navigate("/news")}
							className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
						>
							목록으로
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewsDetail;
