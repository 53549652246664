import { useState, useEffect, useRef } from "react";
import fetchInstagramPosts from "../../services/instagramService";
import TitleAndMore from "./TitleAndMore";
import InstagramCard from "./InstagramCard";
import iconLogo from "../../assets/images/icon_logo_primary.png";

const Instagram = () => {
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const loadPosts = async () => {
			try {
				setLoading(true);
				const data = await fetchInstagramPosts();
				setPosts(data);
			} catch (err) {
				console.error("인스타그램 게시물 로딩 에러:", err);
				setError("인스타그램 게시물을 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadPosts();
	}, []);

	if (loading)
		return (
			<div className="flex justify-center items-center pt-[105px]">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-red-600"></div>
			</div>
		);

	if (error)
		return (
			<div className="flex justify-center items-center pt-[105px] text-red-600">
				{error}
			</div>
		);

	return (
		<section className="py-[105px] bg-white relative overflow-hidden">
			<div className="absolute -top-[25px] -left-[105px] w-[405.47px] h-auto z-0 max-[1703px]:hidden">
				<img
					src={iconLogo}
					alt="봉맛톡 로고"
					className="w-full h-full object-contain opacity-85 animate-float-left"
				/>
			</div>
			<div className="absolute -bottom-[25px] -right-[125px] w-[405.47px] h-auto z-0 max-[1703px]:hidden">
				<img
					src={iconLogo}
					alt="봉맛톡 로고"
					className="w-full h-full object-contain opacity-85 animate-float-right"
				/>
			</div>
			<div className="absolute bottom-[330px] -right-[115px] w-[202.4px] h-auto z-0 max-[1703px]:hidden">
				<img
					src={iconLogo}
					alt="봉맛톡 로고"
					className="w-full h-full object-contain opacity-85 animate-float-right [animation-delay:1s]"
				/>
			</div>

			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-16 relative z-10">
				<TitleAndMore title="Instagram" to="/instagram" />
			</div>

			<div className="relative w-full z-10">
				<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-[50px]">
						{posts.slice(0, 4).map((post) => (
							<InstagramCard key={post.id} post={post} />
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Instagram;
