import { useState, useEffect } from "react";
import {
	fetchOpeningTypes,
	fetchFaqs,
	submitInquiry,
} from "../services/franchiseService";
import { openingSteps } from "../data/openingSteps";
import toast, { Toaster } from "react-hot-toast";

const Franchise = () => {
	const [formData, setFormData] = useState({
		title: "창업 문의드립니다",
		content: "",
		writer: "",
		contact: "",
		desired_region: "",
		opening_type: "",
		budget: "",
		agree_privacy: false,
	});

	const [openingTypes, setOpeningTypes] = useState([]);
	const [selectedType, setSelectedType] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [faqs, setFaqs] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("전체");
	const [expandedFaq, setExpandedFaq] = useState(null);

	useEffect(() => {
		const loadData = async () => {
			try {
				const [typesData, faqsData] = await Promise.all([
					fetchOpeningTypes(),
					fetchFaqs(),
				]);
				setOpeningTypes(typesData);
				setSelectedType(typesData[0]?.title);
				setFaqs(faqsData);
			} catch (err) {
				setError("데이터를 불러오는데 실패했습니다.");
			} finally {
				setLoading(false);
			}
		};

		loadData();
	}, []);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: type === "checkbox" ? checked : value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// 필수 필드 검증
		if (!formData.writer || !formData.content || !formData.agree_privacy) {
			toast.error("필수 항목을 모두 입력해주세요.");
			return;
		}

		try {
			const inquiryData = {
				...formData,
				opening_type: selectedType
					? openingTypes.find((type) => type.title === selectedType)?.id
					: null,
			};

			await submitInquiry(inquiryData);

			// 폼 초기화
			setFormData({
				title: "창업 문의드립니다",
				content: "",
				writer: "",
				contact: "",
				desired_region: "",
				opening_type: "",
				budget: "",
				agree_privacy: false,
			});

			toast.success("문의가 접수되었습니다. 빠른 시일 내에 연락드리겠습니다.");
		} catch (error) {
			toast.error("문의 접수에 실패했습니다. 다시 시도해주세요.");
		}
	};

	const franchiseInfo = [
		{
			title: "초기 투자비용",
			description: "5,000만원 ~ 8,000만원",
			icon: "💰",
		},
		{
			title: "평균 매장 크기",
			description: "10평 ~ 20평 (33㎡ ~ 66㎡)",
			icon: "🏪",
		},
		{
			title: "평균 회수 기간",
			description: "12개월 ~ 18개월",
			icon: "📈",
		},
		{
			title: "로열티",
			description: "월 매출의 3%",
			icon: "💎",
		},
	];

	const formatAmount = (amount) => {
		if (!amount) return "-";
		return `${(amount / 10000).toLocaleString()}만원`;
	};

	const selectedTypeItems =
		openingTypes.find((type) => type.title === selectedType)?.items || [];

	// FAQ 카테고리 목록 생성
	const faqCategories = ["전체", ...new Set(faqs.map((faq) => faq.category))];

	// 필터링된 FAQ 목록
	const filteredFaqs = faqs.filter(
		(faq) => selectedCategory === "전체" || faq.category === selectedCategory
	);

	return (
		<div className="py-16 bg-gray-50">
			<Toaster position="top-center" />
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				{/* 창업 안내 섹션 */}
				<section className="mb-16">
					<h1 className="text-4xl font-bold text-center mb-12">창업 안내</h1>

					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
						{franchiseInfo.map((info) => (
							<div
								key={info.title}
								className="bg-white rounded-lg p-6 shadow-lg text-center"
							>
								<div className="text-4xl mb-4">{info.icon}</div>
								<h3 className="text-xl font-bold mb-2">{info.title}</h3>
								<p className="text-gray-600">{info.description}</p>
							</div>
						))}
					</div>

					<div className="bg-red-50 rounded-lg p-8 mb-16">
						<h2 className="text-2xl font-bold mb-8">창업 절차</h2>
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
							{openingSteps.map((step) => (
								<div
									key={step.step}
									className="bg-white rounded-lg p-6 shadow-md flex flex-col items-center"
								>
									<div className="relative mb-4">
										<img
											src={step.icon}
											alt={step.title}
											className="w-16 h-16 object-contain"
										/>
										<div className="absolute -top-2 -right-2 bg-red-600 text-white w-6 h-6 rounded-full flex items-center justify-center text-sm font-bold">
											{step.step}
										</div>
									</div>
									<h3 className="text-lg font-bold mb-2 text-center">
										{step.title}
									</h3>
									<p className="text-gray-600 text-sm text-center">
										{step.description}
									</p>
								</div>
							))}
						</div>
					</div>
				</section>

				{/* 점포 유형별 개설비용 섹션 */}
				<section className="mb-16">
					<h2 className="text-2xl font-bold text-center mb-8">
						점포 유형별 개설비용
					</h2>

					{/* 점포 유형 탭 */}
					<div className="flex justify-center mb-8">
						<div className="inline-flex rounded-lg border border-gray-200 bg-white p-1">
							{openingTypes.map((type) => (
								<button
									key={type.id}
									onClick={() => setSelectedType(type.title)}
									className={`px-4 py-2 rounded-md ${
										selectedType === type.title
											? "bg-red-600 text-white"
											: "text-gray-600 hover:text-red-600"
									}`}
								>
									{type.title}
								</button>
							))}
						</div>
					</div>

					{/* 비용 테이블 */}
					<div className="bg-white rounded-lg shadow-lg overflow-hidden">
						<table className="w-full">
							<thead className="bg-gray-50">
								<tr>
									<th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
										항목
									</th>
									<th className="px-6 py-3 text-right text-sm font-semibold text-gray-900">
										금액
									</th>
									<th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
										비고
									</th>
								</tr>
							</thead>
							<tbody className="divide-y divide-gray-200">
								{selectedTypeItems.map((item) => (
									<tr key={item.id}>
										<td className="px-6 py-4 text-sm text-gray-900">
											{item.title}
										</td>
										<td
											className={`px-6 py-4 text-sm text-right ${
												item.is_promotion
													? "text-primary font-bold"
													: "text-gray-900"
											}`}
										>
											{formatAmount(item.amount)}
										</td>
										<td
											className={`px-6 py-4 text-sm ${
												item.is_promotion
													? "text-primary font-bold"
													: "text-gray-500"
											}`}
										>
											{item.content}
											{item.is_vat && " (VAT 별도)"}
										</td>
									</tr>
								))}
								<tr className="bg-gray-50">
									<td className="px-6 py-4 font-bold">합계</td>
									<td className="px-6 py-4 text-right font-bold">
										{formatAmount(
											selectedTypeItems
												.filter((item) => item.amount)
												.reduce((sum, item) => sum + item.amount, 0)
										)}
									</td>
									<td className="px-6 py-4 text-sm text-gray-500">
										인테리어 비용 별도
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					{/* 주의사항 */}
					<div className="mt-4 text-sm text-gray-500">
						<p>
							* 상기 금액은 예상 비용으로, 실제 비용은 상황에 따라 변동될 수
							있습니다.
						</p>
						<p>* 인테리어 비용은 평수와 상황에 따라 상이합니다.</p>
						<p>
							* 프로모션 항목은 기간 한정이며, 사전 고지 없이 변경될 수
							있습니다.
						</p>
					</div>
				</section>

				{/* FAQ 섹션 */}
				<section className="mb-16">
					<h2 className="text-2xl font-bold text-center mb-8">
						자주 묻는 질문
					</h2>

					{/* 카테고리 필터 */}
					<div className="flex justify-center mb-8">
						<div className="inline-flex rounded-lg border border-gray-200 bg-white p-1">
							{faqCategories.map((category) => (
								<button
									key={category}
									onClick={() => setSelectedCategory(category)}
									className={`px-4 py-2 rounded-md ${
										selectedCategory === category
											? "bg-red-600 text-white"
											: "text-gray-600 hover:text-red-600"
									}`}
								>
									{category}
								</button>
							))}
						</div>
					</div>

					{/* FAQ 아코디언 */}
					<div className="space-y-4">
						{filteredFaqs.map((faq) => (
							<div
								key={faq.id}
								className="bg-white rounded-lg shadow-md overflow-hidden"
							>
								<button
									className="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-gray-50"
									onClick={() =>
										setExpandedFaq(expandedFaq === faq.id ? null : faq.id)
									}
								>
									<div className="flex items-center">
										<span className="inline-block px-2 py-1 text-xs font-semibold text-red-600 bg-red-100 rounded-full mr-3">
											{faq.category}
										</span>
										<span className="font-medium">{faq.question}</span>
									</div>
									<svg
										className={`w-5 h-5 transform transition-transform ${
											expandedFaq === faq.id ? "rotate-180" : ""
										}`}
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M19 9l-7 7-7-7"
										/>
									</svg>
								</button>
								{expandedFaq === faq.id && (
									<div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
										<p className="text-gray-600 whitespace-pre-line">
											{faq.answer}
										</p>
									</div>
								)}
							</div>
						))}
					</div>
				</section>

				{/* 창업 문의 폼 섹션 */}
				<section className="bg-white rounded-lg shadow-lg p-8">
					<h2 className="text-2xl font-bold mb-8 text-center">창업 문의하기</h2>
					<form onSubmit={handleSubmit} className="max-w-2xl mx-auto">
						<div className="mb-6">
							<label htmlFor="writer" className="block text-gray-700 mb-2">
								이름 *
							</label>
							<input
								type="text"
								id="writer"
								name="writer"
								required
								value={formData.writer}
								onChange={handleChange}
								className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
							/>
						</div>

						<div className="mb-6">
							<label htmlFor="contact" className="block text-gray-700 mb-2">
								연락처
							</label>
							<input
								type="tel"
								id="contact"
								name="contact"
								value={formData.contact}
								onChange={handleChange}
								className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
							/>
						</div>

						<div className="mb-6">
							<label
								htmlFor="desired_region"
								className="block text-gray-700 mb-2"
							>
								창업 희망지역
							</label>
							<input
								type="text"
								id="desired_region"
								name="desired_region"
								value={formData.desired_region}
								onChange={handleChange}
								className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
							/>
						</div>

						<div className="mb-6">
							<label htmlFor="budget" className="block text-gray-700 mb-2">
								예상 창업 자금
							</label>
							<select
								id="budget"
								name="budget"
								value={formData.budget}
								onChange={handleChange}
								className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
							>
								<option value="">선택해주세요</option>
								<option value="5,000만원 미만">5,000만원 미만</option>
								<option value="5,000만원~7,000만원">5,000만원~7,000만원</option>
								<option value="7,000만원~1억원">7,000만원~1억원</option>
								<option value="1억원 이상">1억원 이상</option>
							</select>
						</div>

						<div className="mb-6">
							<label htmlFor="content" className="block text-gray-700 mb-2">
								문의사항 *
							</label>
							<textarea
								id="content"
								name="content"
								rows="4"
								required
								value={formData.content}
								onChange={handleChange}
								className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
							></textarea>
						</div>

						<div className="mb-6">
							<label className="flex items-center">
								<input
									type="checkbox"
									name="agree_privacy"
									checked={formData.agree_privacy}
									onChange={handleChange}
									className="mr-2"
									required
								/>
								<span className="text-sm text-gray-600">
									개인정보 수집 및 이용에 동의합니다. *
								</span>
							</label>
						</div>

						<div className="text-center">
							<button
								type="submit"
								className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-8 rounded-lg transition-colors"
								aria-label="창업 문의 제출하기"
							>
								문의하기
							</button>
						</div>
					</form>
				</section>
			</div>
		</div>
	);
};

export default Franchise;
