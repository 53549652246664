import PropTypes from "prop-types";

const InstagramCard = ({ post }) => {
	const handleClick = () => {
		if (post.link) {
			window.open(post.link, "_blank", "noopener,noreferrer");
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			handleClick();
		}
	};

	return (
		<div
			className="group w-[273.96px] flex-shrink-0 cursor-pointer shadow-[8px_8px_8px_rgba(0,0,0,0.05)] mb-4"
			onClick={handleClick}
			onKeyDown={handleKeyDown}
			role="button"
			tabIndex={0}
			aria-label={`인스타그램 포스트: ${post.title}`}
		>
			<div className="relative w-[273.96px] h-[317.06px] overflow-hidden">
				<img
					src={post.image}
					alt={post.title}
					className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
				/>
				<div className="absolute inset-0 bg-black/70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col items-center justify-center">
					<div className="w-8 h-8">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="white"
						>
							<path d="M12 4.5v15m7.5-7.5h-15" />
						</svg>
					</div>
					<div className="flex flex-col items-center justify-center m-2">
						<p className="text-white text-[10px] font-pretendard text-center">
							{post.hashtag}
						</p>
						<p className="text-white text-[80px] font-pretendard font-light">
							+
						</p>
					</div>
				</div>
			</div>

			<div className="flex justify-between items-center h-[45.58px] px-[11.58px]">
				<span className="text-pretendard text-[10px] font-light text-gray400">
					@bongmaettoek_official
				</span>
				<span className="text-pretendard text-[10px] text-primary font-medium">
					+팔로워
				</span>
			</div>
		</div>
	);
};

InstagramCard.propTypes = {
	post: PropTypes.shape({
		id: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		hashtag: PropTypes.string.isRequired,
		image: PropTypes.string,
		link: PropTypes.string,
	}).isRequired,
};

export default InstagramCard;
