import { BASE_URL } from "./apiConfig";

export const fetchOpeningTypes = async () => {
	try {
		const response = await fetch(`${BASE_URL}/franchise/api/opening-types/`);
		if (!response.ok) throw new Error("Failed to fetch opening types");
		return await response.json();
	} catch (error) {
		console.error("Error fetching opening types:", error);
		throw error;
	}
};

export const fetchFaqs = async () => {
	try {
		const response = await fetch(`${BASE_URL}/franchise/api/faqs/`);
		if (!response.ok) throw new Error("Failed to fetch FAQs");
		return await response.json();
	} catch (error) {
		console.error("Error fetching FAQs:", error);
		throw error;
	}
};

export const submitInquiry = async (inquiryData) => {
	try {
		const response = await fetch(`${BASE_URL}/franchise/api/inquiries/`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(inquiryData),
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || "Failed to submit inquiry");
		}

		return await response.json();
	} catch (error) {
		console.error("Error submitting inquiry:", error);
		throw error;
	}
};
