import step1 from "../assets/images/open/step1.png";
import step2 from "../assets/images/open/step2.png";
import step3 from "../assets/images/open/step3.png";
import step4 from "../assets/images/open/step4.png";
import step5 from "../assets/images/open/step5.png";
import step6 from "../assets/images/open/step6.png";
import step7 from "../assets/images/open/step7.png";
import step8 from "../assets/images/open/step8.png";

export const openingSteps = [
	{
		step: 1,
		icon: step1,
		title: "개설상담",
		description:
			"전화 홈페이지 등록 등 다양한 방법으로 의뢰를 하실 수 있습니다.",
	},
	{
		step: 2,
		icon: step2,
		title: "점포선정 및 실측",
		description: "입지분석 및 선정 등 사업 타당성을 분석합니다.",
	},
	{
		step: 3,
		icon: step3,
		title: "점포승인",
		description: "꼼꼼하고 면밀한 검토 후 점포 승인을 하게 됩니다.",
	},
	{
		step: 4,
		icon: step4,
		title: "착공",
		description: "인테리어 / 시설공사 시공이 진행됩니다.",
	},
	{
		step: 5,
		icon: step5,
		title: "사업자등록",
		description: "점포계약 후 가맹계약을 체결합니다.",
	},
	{
		step: 6,
		icon: step6,
		title: "이론실습 교육",
		description: "전체적인 개점 준비와 점주님의 개점 교육이 진행됩니다.",
	},
	{
		step: 7,
		icon: step7,
		title: "완공 및 오픈준비",
		description:
			"집기류, 소모품, 비품, 판촉물, 초도물량 입고 준비를 하며 오픈준비를 진행합니다.",
	},
	{
		step: 8,
		icon: step8,
		title: "오픈",
		description: "봉천동매운떡볶이 매장 오픈을 축하드립니다!",
	},
];
